.container{
    padding-top: 40px;
    max-width: 1160px;
}

#search{
    padding-bottom: 20px;
}

.input-container {
	padding-bottom: 1em;
}
.left-inner-addon {
    position: relative;
}
.left-inner-addon input {
    padding-left: 50px !important; 
}
.left-inner-addon i {
    position: absolute;
    padding: 16px 20px;
    pointer-events: none;
}

i{
    color: #C5C5C5;
}

.inp{
    border: none;
    background-color: white;
    border-radius: 50px;
    height: 46px;
    width: 241px;
    color: #C5C5C5;
    font-size: 14px;
}

.badge{
    position: absolute;
    width: 118px;
    height: 34px;
    border-radius: 0px 12px 12px 0px;
    background-image: linear-gradient(#4A9BB2, #48337B);
    top: 12px;
}

.badge-title{
    color: #fff;
    font-size: 14px;
    padding: 5px;
}

#card{
    background-color: #fff;
    width: 1118px;
    margin: 20px 20px;
    border-radius: 12px;
    padding: 30px 0px 0px 0px;
    height: 248px;
}

#img {
    height: 185px;
    width: 318px;
    border-radius: 0px 12px 12px 0px;
}

.title{
    color: #484987;
    font-size: 20px;
    font-weight: 700;
}

.description{
    font-size: 14px;
    color: #9798A7;
}

.more{
    font-size: 14px;
    font-weight: 700;
    color: #484987;
    padding: 0px 8px;
}

#container-more{
    padding-top: 30px;
}

/* .icon-more{
    width: 19px;
    height: 3px;
    border-radius: 1,5px;
    background-color: #4DF0E0;
} */

.line{
    width: 19px;
    height: 3px;
    border-bottom: 4px solid #4df0e0;
    border-radius: 2px 2px 2px 2px;
    /* position: absolute; */
    padding: 5px;
}

a{
    color: #484987;
}

a:link {
    text-decoration: none;
}
  
a:visited {
    text-decoration: none;
}
  
a:hover {
    text-decoration: none;
}
  
a:active {
    text-decoration: none;
}